import React from 'react'
import fi from 'date-fns/locale/fi'
import en from 'date-fns/locale/en-US'

const monthCapitalizedEnUS = (monthName: string) => monthName

const monthCapitalizedFiFI = (monthName: string) => {
  switch (monthName) {
    case 'January':
      return 'Tammikuu'
    case 'February':
      return 'Helmikuu'
    case 'March':
      return 'Maaliskuu'
    case 'April':
      return 'Huhtikuu'
    case 'May':
      return 'Toukokuu'
    case 'June':
      return 'Kesäkuu'
    case 'July':
      return 'Heinäkuu'
    case 'August':
      return 'Elokuu'
    case 'September':
      return 'Syyskuu'
    case 'October':
      return 'Lokakuu'
    case 'November':
      return 'Marraskuu'
    case 'December':
      return 'Joulukuu'
    default:
      return monthName
  }
}

export const US_EMPLOYEE_HANDBOOK_LINK =
  'https://docs.google.com/document/d/1sxH7sDSZNERO2dtqx590BlDfIAkGxTIsAtSnez9VNBg'

export const NL_EMPLOYEE_HANDBOOK_LINK =
  'https://sites.google.com/reaktor.fi/reaktor-amsterdam/employment-matters/handbook/time-off'

export const enUS = {
  language: 'US',
  ietfLanguageTag: 'en-US',
  datetime: {
    dateDisplayShort: 'M/D',
    dateDisplayLong: 'MM/DD/YYYY',
    dateFnsLocale: en,
    weekdayFormat: 'ddd',
    rangeFormat: (start: Date, end: Date) => {
      const rangeStart = () => (!end || start.getFullYear() !== end.getFullYear() ? 'MM/DD/YYYY -' : 'MM/DD -')
      const startFormat = start ? rangeStart() : '-'
      const endFormat = end ? ' MM/DD/YYYY' : '>'
      return { startFormat, endFormat }
    },
  },
  texts: {
    absences: {
      unplannedAbsences: 'Sick leaves',
      quota: 'overage',
      noSickLeavesRecorded: 'No sick leaves recorded',
    },
    addEntry: 'Add entry',
    autosave: {
      unsentChanges: 'Unsaved changes',
      postingChanges: 'Saving changes...',
      saveSuccessful: 'Changes saved!',
      saveFail: 'Save failed :(',
    },
    backOffice: 'Back Office',
    backToHoursClient: 'Back to main hours client',
    away: 'away',
    billingRatio: 'Billing ratio',
    change: 'Change',
    changeToSelf: 'Change to self',
    close: 'Close',
    codeInputNoResults: 'No results.',
    copyLastDay: 'Copy last day',
    delete: 'Delete',
    editProjectsAndTasks: 'Edit projects and tasks',
    employees: 'Employees',
    entryNotePlaceholder: 'description',
    flexitimeOverNormalRange: 'Flexitime over normal range',
    monthUnlockedError: (monthName: string) => `${monthName} is not marked as complete`,
    holidays: 'Holidays',
    hourCode: 'hour code',
    hourCodePolicy: {
      travel22: {
        useSameTimeZone: 'Use same time zone for start-end times',
        note: 'city of departure - city of arrival',
        area: 'Select travel area',
        local: 'Domestic/One continent',
        localDescription: 'e.g. Tampere-Turku, Helsinki-Lisbon, or Osaka-Tokyo',
        intercontinental: 'Intercontinental',
        intercontinentalDescription: 'e.g. Helsinki-New York or Tokyo-Amsterdam',
        intercontinentalCompensation: 'Compensation',
        intercontinentalCompensationDescription: (hours: number) =>
          `Intercontinental travel fills up your daily hours up to ${hours}h. Excess is compensated at 50% of hourly rate. Read more about Reaktor's travel policy and what counts as travel time on `,
        intercontinentalCompensationLinkText: 'Hug Info',
        intercontinentalCompensationLinkTextPT: 'Lisbon pages',
        intercontinentalCompensationLinkTextNL: 'Amsterdam pages',
        intercontinentalCompensationLinkTextJP: 'Reaktor Japan Handbook',
        compensation: 'Select compensation for travel time',
        compensationFlexitime: '50% work hours',
        compensationFlexitimeHours: (hours: number) => `${hours}h added to your daily balance`,
        compensationSalary: '50% hourly salary',
        compensationSalaryHours: '50% hourly pay on top of your monthly salary',
        compensationHelp:
          "Reaktor compensates half of travel time. You can choose either 50% hourly salary or 50% of work hours. Read more about Reaktor's travel policy and what counts as travel time on ",
        compensationLinkText: 'Hug Info',
        compensationHelpPT:
          "Reaktor compensates travel time as 50% working time. Read more about Reaktor's travel policy and what counts as travel time on ",
        compensationLinkTextPT: 'Lisbon pages',
        compensationHelpNL: "Read more about Reaktor's travel policy and what counts as travel time on ",
        compensationLinkTextNL: 'Amsterdam pages',
        compensationHelpJP: "Read more about Reaktor's travel policy and what counts as travel time on ",
        compensationLinkTextJP: 'Reaktor Japan Handbook',
      },
    },
    hourly: 'hourly',
    hours: 'hours',
    hoursAdmin: 'Hours Admin',
    hoursAdminDisclaimer:
      'The new Back Office UI is a work in progress. For most functionality, you should still go to the ',
    hoursAdminDisclaimerLink: 'old admin UI',
    hoursSummary: {
      backToEditing: 'Go back to editing',
      confirmEdit: (monthName: string, isLocked: boolean) =>
        isLocked
          ? `Are you sure you want to open ${monthName} hours for editing? Changes in old hour entries might require extra work from hug and invoicing. Ask your team or #help-hug if you need help.`
          : `Are you sure you want to mark ${monthName} hours complete?`,
      markComplete: 'Mark complete',
      monthMarkedAsComplete: (monthName: string) => `${monthName} hours are marked as complete`,
      monthSummary: (monthName: string) => monthName,
      summary: 'Summary',
      fillAllHoursCorrectly: 'Fill in all hours correctly for this month to mark them complete',
      markCompleteBeforeAbsence: 'Remember to mark your hours as complete before your time off!',
      deltaFlex: 'Flexitime change',
      total: 'Total',
    },
    impersonatingAs: 'Impersonating as $',
    impersonationGo: 'Go',
    logOut: 'Logout',
    serverConnectivityProblems: 'Problems with server connection',
    notReporting: 'not reporting',
    partialWeek: ', away on $',
    possiblyErroneousEntriesTitle: 'Possibly erroneous entries',
    refresh: 'Refresh',
    searchForPerson: 'search for a person',
    showBillingRatio: 'Show billing ratio',
    showMonthlySummary: 'Show monthly summary',
    showAbsences: 'Show sick days',
    showMore: 'Show more...',
    showStartEndTimes: 'Show start/end times',
    showVacations: 'Show vacations',
    subcontractor: 'subcontractor',
    subcontractors: 'Subcontractors',
    timeRangeStart: 'start',
    timeRangeEnd: 'end',
    today: 'Today',
    total: 'sum',
    summaryByProject: 'project',
    totalHoursYesterday: 'Total hours difference as of yesterday:',
    unknown: 'unknown',
    vacations: {
      vacations: 'Vacations',
      unpaidVacations: 'Unpaid vacation',
      floatingHolidays: 'Floating holidays',
      vacationDays: 'Vacation days',
      workdays: 'days',
      saturdays: 'Sats',
      days: 'Days',
      available: 'Available',
      availableBeginningOf: 'Available beginning of',
      availableOnBeginningOfContract: 'Available beginning of contract',
      availableDateStr: 'on $',
      from: '(from $)',
      used: 'Used',
      earned: 'Earned',
      earnedThisYear: 'Earned this year',
      balance: 'Balance $CHECKPOINT_DAY$',
      planned: 'Planned',
      remaining: 'Remaining',
      remainingBalance: 'Remaining balance',
      transferred: 'Transferred',
      unpaidVacationFormatHours: (hours: string) => `${hours} h`,
      unpaidVacationFormatDaysAndHours: (days: number, hours: string) => `${days} d ${hours} h`,
      earnedAvailableTooltipNL: `You have $AVAILABLE$ days for $YEAR$ ($TRANSFERRED$ are transferred from previous years and $YEARLY_SUM$ are added for this year.

You earn 2.08 days per month, but can use all available days whenever this year. So far you have earned $EARNED$/$YEARLY_SUM$ of this year's vacation days.`,
      earnedAvailableTooltipNLFixedTerm:
        'You have $AVAILABLE$ vacation days, which you can use whenever you like during your contract period.',
      vacationsTooltipUSA: (
        <>
          You earn 1.67 days per month, giving a total of <b>20 vacation days per full year</b>. Vacation balance is
          carried over to next year. Read more from the{' '}
          <a href={US_EMPLOYEE_HANDBOOK_LINK} target='_blank' rel='noopener noreferrer'>
            Employee Handbook
          </a>
          .
        </>
      ),
      readInAMSHandbook: 'Read more about AMS vacations in Handbook',
      readInUSAHandbook: 'Read more about INC vacations in Handbook',
      floatingHolidaysTooltip: (
        <>
          You earn 0.75 days per month, giving a total of <b>9 holidays per full year</b>. Holiday balance is carried
          over to next year. Read more from the{' '}
          <a href={US_EMPLOYEE_HANDBOOK_LINK} target='_blank' rel='noopener noreferrer'>
            Employee Handbook
          </a>
          .
        </>
      ),
    },
    admin: {
      accessForbidden: "You don't have access to this page",
      navBar: {
        vacations: 'Vacations',
        unpaidLeaves: 'Unpaid leaves',
        reaktorIncLeaves: 'Sick leaves (Inc)',
        reaktorIncFloatingHolidays: 'Floating holidays (Inc)',
        projectAndTaskSearch: 'Project and task search',
        usersWithUnlockedHours: 'Users with unlocked hours',
        flexiHours: 'Flexihours',
      },
      vacations: {
        title: 'Holidays',
        country: 'Country',
        name: 'Name',
        username: 'User Id',
        date: 'Date',
        workdaysWithSats: 'Workdays (Sat)',
        usedWithSats: 'Used (Sat)',
        plannedWithSats: 'Planned (Sat)',
        totalWithSats: 'Total (Sat)',
        days: 'Days',
        used: 'Used',
        planned: 'Planned',
        total: 'Total',
        availableInYear: (year: number) => `Available in ${year}`,
        available: 'Available',
        remaining: 'Remaining',
        transferred: 'Transferred',
        earned: 'Earned',
        agreed: 'Agreed',
      },
      leaveEditor: {
        save: 'Save changes',
        cancel: 'Cancel',
        saveError: 'Error updating leaves',
      },
      unlockedHoursStats: {
        noUsersForCountry: 'No users with unlocked hours for selected country!',
        name: 'Name',
        country: 'Country',
        total: 'Total',
        monthName: monthCapitalizedEnUS,
      },
      unpaidLeaves: {
        title: 'Unpaid leaves',
        availableUnpaidDays: 'Available unpaid days',
        thisSeason: 'This season',
        nextSeason: 'Next season',
      },
      reaktorIncLeaves: {
        title: 'Sick leaves',
        sickLeavesAvailable: 'Sick leave hours available',
        sickLeave: 'Sick leaves this year (hours)',
      },
      reaktorIncFloatingHolidays: {
        title: 'Floating holidays (Inc)',
        balance: 'Balance',
        earned: 'Earned',
        usedDays: 'Used',
        plannedDays: 'Planned',
        remainingDays: 'Remaining today',
      },
      projectAndTaskSearch: {
        title: 'Project and task search',
        username: 'Name',
        day: 'Day',
        startTime: 'Start time',
        endTime: 'End time',
        hours: 'Hours',
        hourCode: 'Hour Code',
        note: 'Note',
        responseIsLimited: 'Results are limited, try narrowing your search',
        hoursSummed: 'Total hours',
        entries: 'Entries',
        uniqueUsers: 'Unique users',
        errorDuringSearch: (validation: string) => `Error during search, check parameter(s): ${validation}`,
      },
      flexiHoursAdjustments: {
        title: 'Flexihour adjustments',
        adjustments: 'Adjustments',
        columns: {
          name: 'Name',
          uid: 'User ID',
          currentFlex: 'Current flexihours balance',
          date: 'Date',
          adjustmentHours: 'Hours',
          note: 'Note',
        },
        adjust: 'Adjust',
        cancel: 'Cancel',
        save: 'Save',
      },
    },
    validationErrors: {
      suspiciouslyFewHours: {
        title: 'Short workday (< 80% of a full day)',
        button: 'Use flexitime',
      },
      suspiciouslyManyHours: {
        title: 'Long workday (≥ 14 h)',
        button: "It's correct",
      },
      moreAbsenceThanWorkDayLength: ({
        absences,
        dayLength,
      }: {
        absences: string | number
        dayLength: string | number
      }) => `Absences together (${absences} h) > workday length (${dayLength} h)`,
      absenceLongerThanWorkDay: {
        title: ({ dayLength }: { dayLength: string | number }) => `Absence > workday length (${dayLength} h)`,
        button: ({ dayLength }: { dayLength: string | number }) => `Change hours to ${dayLength} h`,
      },
      wrongLengthFullDayAbsence: {
        title: ({ dayLength }: { dayLength: string | number }) => `Wrong length full day (${dayLength} h) absence`,
        button: ({ dayLength }: { dayLength: string | number }) => `Change hours to ${dayLength} h`,
      },
      absenceOnNotWorkDay: {
        title: 'Absence on a non-working day',
        button: 'Remove it',
      },
      absenceIncreasingFlextime: {
        title: 'Absence cannot increase flexitime',
        button: ({ validAbsenceHours }: { validAbsenceHours: string | number }) =>
          `Shorten absence to ${validAbsenceHours} h`,
      },
      hoursLongerThanPeriod: {
        title: ({ duration }: { duration: string | number }) =>
          `Hours > the time between start & end time (${duration} h)`,
        button: ({ duration }: { duration: string | number }) => `Change hours to ${duration} h`,
      },
      invalidFlextime: 'Invalid flextime',
      unknownProject: 'Unknown code',
      outsideProjectDuration: ({ duration }: { duration: string }) => `Outside code duration (${duration})`,
      unknownTask: 'Unknown code',
      outsideTaskDuration: ({ duration }: { duration: string }) => `Outside code duration (${duration})`,
      missingRequiredNote: 'A note is required for this code',
      invalidStartTime: '',
      invalidEndTime: '',
      invalidHours: '',
      alertOverlappingStandby: 'Alert and standby codes must not overlap.',
    },
    vuosilomaAvailable: 'Available',
    vuosilomaPlanned: 'Planned',
    vuosilomaRemaining: 'Remaining',
    vuosilomaUsed: 'Used',
    vuosilomaWithSaturdays: 'With Saturdays',
    vuosilomaWorkDays: 'Work days',
    weekendTitle: 'Weekend',
    errors: 'Errors',
    showErrors: 'Show errors',
  },
}

export const fiFI = {
  language: 'FI',
  ietfLanguageTag: 'fi-FI',
  datetime: {
    dateDisplayShort: 'D.M.',
    dateDisplayLong: 'D.M.YYYY',
    dateFnsLocale: fi,
    weekdayFormat: 'dd',
    rangeFormat: (start: Date, end: Date) => {
      const rangeStart = () => {
        if (!end || start.getFullYear() !== end.getFullYear()) return 'D.M.YYYY -'
        return start.getMonth() !== end.getMonth() ? 'D.M. -' : 'D. -'
      }
      const startFormat = start ? rangeStart() : '-'
      const endFormat = end ? ' D.M.YYYY' : '>'
      return { startFormat, endFormat }
    },
  },
  texts: {
    absences: {
      unplannedAbsences: 'Sairaspoissaolot',
      quota: 'ylitys',
      noSickLeavesRecorded: 'Ei kirjattuja sairaspoissaoloja',
    },
    addEntry: 'Lisää merkintä',
    autosave: {
      unsentChanges: 'Tallentamattomia muutoksia',
      postingChanges: 'Tallennetaan...',
      saveSuccessful: 'Tallennettu!',
      saveFail: 'Tallennus epäonnistui :(',
    },
    away: 'poissa',
    backOffice: 'Back Office',
    backToHoursClient: 'Palaa tuntisyöttöön',
    billingRatio: 'Laskutusaste',
    change: 'Vaihda',
    changeToSelf: 'Vaihda omaan',
    close: 'Sulje',
    codeInputNoResults: 'Ei tuloksia.',
    copyLastDay: 'Kopioi edellinen päivä',
    delete: 'Poista',
    editProjectsAndTasks: 'Muokkaa projekteja ja tehtäviä',
    employees: 'Työntekijät',
    entryNotePlaceholder: 'kuvaus',
    flexitimeOverNormalRange: 'Liukumaa normaalirajojen yli',
    monthUnlockedError: (monthName: string) => {
      return `${monthCapitalizedFiFI(monthName)}n tunteja ei ole merkitty valmiiksi`
    },
    holidays: 'Vuosilomat',
    hourCode: 'tuntikoodi',
    hourCodePolicy: {
      travel22: {
        useSameTimeZone: 'Käytä samaa aikavyöhykettä alkamis- ja päättymisajalle',
        note: 'Lähtökaupunki - saapumiskaupunki',
        area: 'Valitse matkustusalue',
        local: 'Kotimaa/mantereen sisällä',
        localDescription: 'esim. Tampere-Turku, Helsinki-Lissabon tai Osaka-Tokio',
        intercontinental: 'Mannerten välillä',
        intercontinentalDescription: 'esim. Helsinki-New York tai Tokio-Amsterdam',
        intercontinentalCompensation: 'Matka-ajan korvaus',
        intercontinentalCompensationDescription: (hours: number) =>
          `Mannertenvälisissä matkoissa matka-aika kerryttää työtunteja täysinä tunteina ${hours}h/päivä saakka. Tästä ylimenevät tunnit korvataan 50% tuntipalkkana. Lue lisää Reaktorin matkustuskäytännöstä ja mikä lasketaan matka-ajaksi `,
        intercontinentalCompensationLinkText: 'Halin sivuilta',
        intercontinentalCompensationLinkTextPT: 'Lissabonin sivuilta',
        intercontinentalCompensationLinkTextNL: 'Amsterdamin sivuilta',
        intercontinentalCompensationLinkTextJP: 'Reaktor Japan Handbook -sivuilta',
        compensation: 'Valitse korvaus matka-ajalta',
        compensationFlexitime: '50% työtunnit',
        compensationFlexitimeHours: (hours: number) => `${hours}h työaikaa osaksi päivän saldoa`,
        compensationSalary: '50% tuntipalkka',
        compensationSalaryHours: `50% tuntipalkka kuukausipalkan päälle`,
        compensationHelp:
          'Reaktor korvaa puolet matkustusajasta. Voit valita korvaukseksi 50% tuntipalkan tai 50% työajan matkatunneilta. Lue lisää Reaktorin matkustuskäytännöstä ja mikä lasketaan matka-ajaksi ',
        compensationLinkText: 'Halin sivuilta',
        compensationHelpPT:
          'Reaktor korvaa matkustusajan 50%:sena työaikana. Lue lisää Reaktorin matkustuskäytännöstä ja mikä lasketaan matka-ajaksi ',
        compensationLinkTextPT: 'Lissabonin sivuilta',
        compensationHelpNL: 'Lue lisää Reaktorin matkustuskäytännöstä ja mikä lasketaan matka-ajaksi ',
        compensationLinkTextNL: 'Amsterdamin sivuilta',
        compensationHelpJP: 'Lue lisää Reaktorin matkustuskäytännöstä ja mikä lasketaan matka-ajaksi ',
        compensationLinkTextJP: 'Reaktor Japan Handbook -sivuilta',
      },
    },
    hourly: 'tunti',
    hours: 'tunnit',
    hoursAdmin: 'Hours Admin',
    hoursAdminDisclaimer:
      'Uusi Back Office -käyttöliittymä on työn alla. Suurin osa toiminnoista on toistaiseksi saatavilla vain ',
    hoursAdminDisclaimerLink: 'vanhassa admin-käyttöliittymässä',
    hoursSummary: {
      backToEditing: 'Palauta muokattavaksi',
      confirmEdit: (monthName: string, isLocked: boolean) =>
        isLocked
          ? `Haluatko varmasti palauttaa kuukauden muokattavaksi? Muutokset vanhoissa tuntikirjauksissa saattavat aiheuttaa lisätyötä halille ja laskutukseen. Kysy tarvittaessa apua tiimiltäsi tai #help-hug kanavalta.`
          : `Haluatko varmasti merkitä kuukauden valmiiksi?`,
      markComplete: 'Merkitse valmiiksi',
      monthMarkedAsComplete: (monthName: string) => `${monthCapitalizedFiFI(monthName)}n tunnit on merkitty valmiiksi`,
      monthSummary: (monthName: string) => `${monthCapitalizedFiFI(monthName)}n`,
      summary: 'yhteenveto',
      fillAllHoursCorrectly: 'Kirjaa kaikki kuukauden tunnit oikein merkitäksesi ne valmiiksi',
      markCompleteBeforeAbsence: 'Muista merkitä tuntisi valmiiksi ennen poissaoloasi!',
      deltaFlex: 'Liukuman muutos',
      total: 'Yhteensä',
    },
    impersonatingAs: 'Impersonoidaan käyttäjää $',
    impersonationGo: 'Vaihda',
    logOut: 'Kirjaudu ulos',
    serverConnectivityProblems: 'Ongelmia palvelinyhteyden kanssa',
    notReporting: 'ei kirjaa',
    partialWeek: ', poissa $',
    possiblyErroneousEntriesTitle: 'Outouksia tuntikirjauksissa',
    refresh: 'Päivitä',
    searchForPerson: 'etsi henkilöä',
    showBillingRatio: 'Näytä laskutusaste',
    showMonthlySummary: 'Näytä yhteenveto',
    showAbsences: 'Näytä poissaolot',
    showMore: 'Näytä lisää...',
    showStartEndTimes: 'Näytä alkoi/päättyi',
    showVacations: 'Näytä lomat',
    subcontractor: 'alihankkija',
    subcontractors: 'Alihankkijat',
    timeRangeStart: 'alk',
    timeRangeEnd: 'päät',
    today: 'Tänään',
    total: 'yhteensä',
    summaryByProject: 'projektit',
    totalHoursYesterday: 'Tuntisaldo eilisen jälkeen:',
    unknown: 'unknown',
    vacations: {
      vacations: 'Lomapäivät',
      unpaidVacations: 'Palkattomat vapaat',
      floatingHolidays: 'Kelluvat vapaat',
      vacationDays: 'Lomapäivät',
      workdays: 'arkipv',
      saturdays: 'la',
      days: 'Pv',
      available: 'Käytettävissä',
      availableBeginningOf: 'Käytettävissä v. ',
      availableOnBeginningOfContract: 'Käytettävissä sop. alussa',
      availableDateStr: '$',
      from: '($ lähtien)',
      used: 'Pidetty',
      earned: 'Kertynyt',
      earnedThisYear: 'Ansaittu tänä vuonna',
      balance: 'Kertymä $CHECKPOINT_DAY$',
      planned: 'Suunniteltu',
      remaining: 'Jäljellä',
      remainingBalance: 'Jäljellä',
      transferred: 'Siirtynyt',
      unpaidVacationFormatHours: (hours: string) => `${hours} h`,
      unpaidVacationFormatDaysAndHours: (days: number, hours: string) => `${days} pv ${hours} h`,
      earnedAvailableTooltipNL: `Sinulla on $AVAILABLE$ lomapäivää vuodelle $YEAR$ ($TRANSFERRED$ siirtynyt edellisvuosilta, $YEARLY_SUM$ on lisätty tälle vuodelle).

Ansaitset 2.08 päivää kuussa, mutta voit käyttää koko määrän milloin haluat tänä vuonna. Tähän mennessä olet ansainnut $EARNED$/$YEARLY_SUM$ tämän vuoden lomapäivistä.`,
      earnedAvailableTooltipNLFixedTerm:
        'Sinulla on $AVAILABLE$ lomapäivää, jotka voit käyttää milloin tahansa sopimusjaksosi aikana.',
      vacationsTooltipUSA: (
        <>
          Ansaitset 1.67 lomapäivää kuussa, eli <b>20 lomapäivää kokonaisen vuoden aikana</b>. Lomasaldo siirtyy
          seuraavalle vuodelle. Lue lisää{' '}
          <a href={US_EMPLOYEE_HANDBOOK_LINK} target='_blank' rel='noopener noreferrer'>
            Employee Handbookista
          </a>
          .
        </>
      ),
      readInAMSHandbook: 'Lisätietoa AMS lomista Handbookissa',
      readInUSAHandbook: 'Lisätietoa INC lomista Handbookissa',
      floatingHolidaysTooltip: (
        <>
          Ansaitset 0.75 vapaapäivää kuukaudessa, jolloin kerrytät <b>9 vapaapäivää kokonaisen vuoden aikana</b>.
          Vapaapäivät siirtyvät seuraavalle vuodelle. Lue lisää{' '}
          <a href={US_EMPLOYEE_HANDBOOK_LINK} target='_blank' rel='noopener noreferrer'>
            Employee Handbookista
          </a>
          .
        </>
      ),
    },
    admin: {
      accessForbidden: 'Sinulla ei ole pääsyä tälle sivulle',
      navBar: {
        vacations: 'Lomat',
        unpaidLeaves: 'Palkattomat vapaat',
        reaktorIncLeaves: 'Sairaspoissaolot (Inc)',
        reaktorIncFloatingHolidays: 'Kelluvat vapaat (Inc)',
        projectAndTaskSearch: 'Projekti- ja tehtävähaku',
        usersWithUnlockedHours: 'Tuntien lukitus',
        flexiHours: 'Liukumat',
      },
      vacations: {
        title: 'Lomat',
        country: 'Maa',
        name: 'Nimi',
        username: 'Tunnus',
        date: 'Pvm',
        workdaysWithSats: 'Arkipv (la)',
        usedWithSats: 'Pidetty (la)',
        plannedWithSats: 'Suunniteltu (la)',
        totalWithSats: 'Yhteensä (la)',
        days: 'Pv',
        used: 'Pidetty',
        planned: 'Suunniteltu',
        total: 'Yhteensä',
        availableInYear: (year: number) => `Käytettävissä v. ${year}`,
        available: 'Käytettävissä',
        remaining: 'Jäljellä',
        transferred: 'Siirtynyt',
        earned: 'Kertynyt',
        agreed: 'Sovittu',
      },
      leaveEditor: {
        save: 'Tallenna',
        cancel: 'Sulje',
        saveError: 'Virhe päivittäessä vapaita',
      },
      unlockedHoursStats: {
        noUsersForCountry: 'Valitussa maassa ei ole käyttäjiä, joilla olisi lukitsemattomia tunteja.',
        name: 'Nimi',
        country: 'Maa',
        total: 'Yhteensä',
        monthName: monthCapitalizedFiFI,
      },
      unpaidLeaves: {
        title: 'Palkattomat vapaat',
        availableUnpaidDays: 'Palkattomat vapaapäivät',
        thisSeason: 'Tämä lomakausi',
        nextSeason: 'Seuraava lomakausi',
      },
      reaktorIncLeaves: {
        title: 'Sairaspoissaolot',
        sickLeavesAvailable: 'Sairaspoissaolotunteja käytettävissä',
        sickLeave: 'Sairaspoissaoloja tänä vuonna (tuntia)',
      },
      reaktorIncFloatingHolidays: {
        title: 'Kelluvat vapaat (Inc)',
        balance: 'Kertymä',
        earned: 'Ansaittu',
        usedDays: 'Käytetty',
        plannedDays: 'Suunniteltu',
        remainingDays: 'Jäljellä tänään',
      },
      projectAndTaskSearch: {
        title: 'Projektien ja tehtävien haku',
        username: 'Nimi',
        day: 'Päivä',
        startTime: 'Alku',
        endTime: 'Loppu',
        hours: 'Tunnit',
        hourCode: 'Tuntikoodi',
        note: 'Kuvaus',
        responseIsLimited: 'Vastauksien lukumäärä rajoitettu, rajaa hakuehtoja.',
        hoursSummed: 'Tunteja yhteensä',
        entries: 'Rivejä',
        uniqueUsers: 'Henkilöitä yhteensä',
        errorDuringSearch: (validation: string) => `Virhe haussa, tarkista arvot: ${validation}`,
      },
      flexiHoursAdjustments: {
        title: 'Liukumatuntien muutokset',
        adjustments: 'Muutokset',
        columns: {
          name: 'Nimi',
          uid: 'Käyttäjätunnus',
          currentFlex: 'Nykyinen liukumasaldo',
          date: 'Päivämäärä',
          adjustmentHours: 'Tunnit',
          note: 'Kuvaus',
        },
        adjust: 'Muuta',
        cancel: 'Peruuta',
        save: 'Tallenna',
      },
    },
    validationErrors: {
      suspiciouslyFewHours: {
        title: 'Lyhyt työpäivä (< 80% täydestä)',
        button: 'Lisää liukumakirjaus',
      },
      suspiciouslyManyHours: {
        title: 'Pitkä työpäivä (≥ 14 h)',
        button: 'Merkitse tarkistetuksi',
      },
      moreAbsenceThanWorkDayLength: ({
        absences,
        dayLength,
      }: {
        absences: string | number
        dayLength: string | number
      }) => `Poissaolot yhteensä (${absences} h) > työpäivän pituus (${dayLength} h)`,
      absenceLongerThanWorkDay: {
        title: ({ dayLength }: { dayLength: string | number }) => `Poissaolo > työpäivän pituus (${dayLength} h)`,
        button: ({ dayLength }: { dayLength: string | number }) => `Muuta tunneiksi ${dayLength} h`,
      },
      wrongLengthFullDayAbsence: {
        title: ({ dayLength }: { dayLength: string | number }) =>
          `Väärän pituinen koko päivän (${dayLength} h) poissaolo`,
        button: ({ dayLength }: { dayLength: string | number }) => `Muuta tunneiksi ${dayLength} h`,
      },
      absenceOnNotWorkDay: {
        title: 'Poissaolo vapaapäivänä',
        button: 'Poista merkintä',
      },
      absenceIncreasingFlextime: {
        title: 'Poissaolo ei voi kasvattaa liukumaa',
        button: ({ validAbsenceHours }: { validAbsenceHours: string | number }) =>
          `Muuta tunneiksi ${validAbsenceHours} h`,
      },
      hoursLongerThanPeriod: {
        title: ({ duration }: { duration: string | number }) => `Tunnit > alku- ja loppuajan aikaväli (${duration} h)`,
        button: ({ duration }: { duration: string | number }) => `Muuta tunneiksi ${duration} h`,
      },
      invalidFlextime: 'Liukuman tulee olla aina 0 h',
      unknownProject: 'Tuntematon tuntikoodi',
      outsideProjectDuration: ({ duration }: { duration: string }) => `Koodi ei ole voimassa (${duration})`,
      unknownTask: 'Tuntematon tuntikoodi',
      outsideTaskDuration: ({ duration }: { duration: string }) => `Koodi ei ole voimassa (${duration})`,
      missingRequiredNote: 'Tuntikoodi vaatii kuvauksen',
      invalidStartTime: '',
      invalidEndTime: '',
      invalidHours: '',
      alertOverlappingStandby: 'Hälytystyö ja varallaolo eivät voi olla päällekkäin.',
    },
    vuosilomaAvailable: 'Käytettävissä',
    vuosilomaPlanned: 'Suunniteltu',
    vuosilomaRemaining: 'Jäljellä',
    vuosilomaUsed: 'Käytetty',
    vuosilomaWithSaturdays: 'Lauantailliset',
    vuosilomaWorkDays: 'Työpäivät',
    weekendTitle: 'Viikonloppu',
    errors: 'Virheet',
    showErrors: 'Näytä virheet',
  },
}

export const enEN = {
  ...enUS,
  ...{
    language: 'EN',
    ietfLanguageTag: 'en-EN',
    datetime: {
      dateDisplayShort: 'M/D',
      dateDisplayLong: 'YYYY/MM/DD',
      dateFnsLocale: en,
      weekdayFormat: 'ddd',
      rangeFormat: (start: Date, end: Date) => {
        const rangeEnd = () => {
          if (!start || start.getFullYear() !== end.getFullYear()) return ' YYYY/MM/DD'
          return start.getMonth() !== end.getMonth() ? ' MM/DD' : ' DD'
        }
        const startFormat = start ? 'YYYY/MM/DD -' : '-'
        const endFormat = end ? rangeEnd() : '>'
        return { startFormat, endFormat }
      },
    },
  },
}

export const supportedLocales = [enUS, fiFI, enEN]

export const serverLanguageSettingToLocale = (language: string) =>
  language === 'FI' ? fiFI : language === 'US' ? enUS : enEN
