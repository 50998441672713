import React from 'react'
import classNames from 'classnames'

export const InputField: React.FC<{
  label?: string
  value: string
  onChange: (value: string) => void
  isError?: (val: string) => boolean
  className?: string
  errorClassName?: string
  placeholder?: string
}> = ({ label, value, onChange, isError, className, errorClassName, placeholder }) => {
  return (
    <div
      className={classNames(className, {
        [errorClassName ?? '']: errorClassName && isError?.(value),
      })}
    >
      {!!label?.length && <label>{label}</label>}
      <input value={value} onChange={(e) => onChange(e.target.value)} placeholder={placeholder} />
    </div>
  )
}
