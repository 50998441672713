import React, { useEffect, useState } from 'react'
import {
  Country,
  EmployeeBaseDifferenceAdjustment,
  EmployeeBaseDifferenceAdjustments,
  EmployeeName,
  NewBaseDifferenceAdjustment,
} from '../../lib/domain'
import adminClientStyles from '../../css/AdminClient.module.css'
import styles from './FlexiHoursManagement.module.css'
import { postBaseDifferenceAdjustment } from '../../lib/hoursApi'
import { FlexiHoursEditorTable } from './FlexiHoursEditorTable'
import { useAppDispatch, useAppSelector } from '../../lib/hooks'
import Spinner from '../../Spinner'
import { updateCountryBaseDifferenceAdjustmentsAndFlexihours, updateCountryEmployees } from '../util'

type FlexiHoursManagementProps = {
  defaultCountry: Country
  countries: Country[]
}

const appendAdjustment = (
  countryAdjustments: EmployeeBaseDifferenceAdjustments[],
  adjustment: EmployeeBaseDifferenceAdjustment
) => {
  const hasPreviousAdjustments = countryAdjustments.some(
    (employeeAdjustments) => employeeAdjustments.username === adjustment.username
  )

  return hasPreviousAdjustments
    ? countryAdjustments.map((employeeAdjustments) =>
        employeeAdjustments.username === adjustment.username
          ? { ...employeeAdjustments, adjustments: [...employeeAdjustments.adjustments, adjustment] }
          : employeeAdjustments
      )
    : [...countryAdjustments, { username: adjustment.username, adjustments: [adjustment] }]
}

const FlexiHoursManagement: React.FC<FlexiHoursManagementProps> = ({ defaultCountry, countries }) => {
  const [selectedCountry, setSelectedCountry] = useState<Country>(defaultCountry)

  const locale = useAppSelector((state) => state.settings.locale)
  const countryAdjustments = useAppSelector(
    (state) => state.data.adminClient.countryBaseDifferenceAdjustments[selectedCountry.id]
  )
  const countryEmployees = useAppSelector((state) => state.data.adminClient.employeesByCountry[selectedCountry.id])
  const userFlexihours = useAppSelector((state) => state.data.adminClient.currentFlexihours)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (countryAdjustments) {
      return
    }

    if (countryEmployees === undefined) {
      updateCountryEmployees(selectedCountry.id, dispatch).catch((e) => {
        console.error('Could not load employee contracts for user', e)
      })
      return
    }

    updateCountryBaseDifferenceAdjustmentsAndFlexihours(selectedCountry.id, dispatch).catch((e) => {
      console.error('Could not get adjustments and flexihours for country', selectedCountry.id, e)
    })
  }, [selectedCountry.id, countryEmployees])

  if (!countryAdjustments || !countryEmployees) {
    return <Spinner />
  }

  const addAdjustment = async (username: string, adjustment: NewBaseDifferenceAdjustment) => {
    try {
      const newAdjustment = await postBaseDifferenceAdjustment(username, adjustment)
      const newCountryAdjustments = appendAdjustment(countryAdjustments, newAdjustment)

      dispatch({
        type: 'UPDATE_COUNTRY_BASE_DIFFERENCE_ADJUSTMENTS',
        countryBaseDifferenceAdjustments: { [selectedCountry.id]: newCountryAdjustments },
      })
      dispatch({
        type: 'UPDATE_USER_CURRENT_FLEXIHOURS',
        currentFlexihours: {
          ...userFlexihours,
          [username]: (userFlexihours[username] ?? 0) + adjustment.hoursAdjustment,
        },
      })
      return true
    } catch (e) {
      console.error('Could not add flexihours adjustment', e)
      alert('Failed not add flexihours adjustment')
      return false
    }
  }

  const employees = countryEmployees
    .map((employee) => employee.employee)
    .filter((e): e is EmployeeName => e !== undefined)

  const adjustmentsByEmployee = Object.fromEntries(
    countryAdjustments.map((employeeAdjustments) => [employeeAdjustments.username, employeeAdjustments.adjustments])
  )

  const countryFlexihourData = employees.map((employee) => ({
    employee,
    currentFlexiHours: userFlexihours[employee.username] ?? Number.NaN,
    adjustments: adjustmentsByEmployee[employee.username] ?? [],
  }))

  return (
    <div className={adminClientStyles.container}>
      <h1>{locale.texts.admin.flexiHoursAdjustments.title}</h1>
      <div className={styles.countrySelectContainer}>
        <label htmlFor='country'>{locale.texts.admin.unlockedHoursStats.country}</label>
        <select
          data-test='country-select'
          id='country'
          value={selectedCountry.id}
          onChange={(e) => {
            setSelectedCountry(countries.find((c) => c.id === e.target.value) || defaultCountry)
          }}
        >
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.name}
            </option>
          ))}
        </select>
        <FlexiHoursEditorTable employees={countryFlexihourData} addAdjustment={addAdjustment} locale={locale} />
      </div>
    </div>
  )
}

export default FlexiHoursManagement
