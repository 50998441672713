import { EmployeeBaseDifferenceAdjustment, EmployeeName, NewBaseDifferenceAdjustment } from '../../lib/domain'
import styles from './FlexiHoursEditorTable.module.css'
import React from 'react'
import classNames from 'classnames'
import { formatDayMonthYear, today } from '../../lib/date'
import { InputField } from '../../InputField'
import { RootState } from '../../lib/reducers'

type EmployeeAdjustmentsAndFlexihours = {
  employee: EmployeeName
  adjustments: EmployeeBaseDifferenceAdjustment[]
  currentFlexiHours: number
}

type Props = {
  locale: RootState['settings']['locale']
  employees: EmployeeAdjustmentsAndFlexihours[]
  addAdjustment: (username: string, adjustment: NewBaseDifferenceAdjustment) => Promise<boolean>
}

type NewAdjustmentData = {
  hoursAdjustment: string
  note: string
}

type EmployeeRowProps = {
  employee: EmployeeAdjustmentsAndFlexihours
  locale: RootState['settings']['locale']
  saveAdjustment: () => void
  isSelected: boolean
  newAdjustment: NewAdjustmentData | null
  toggleSelected: () => void
  setNewAdjustment: (value: NewAdjustmentData | null) => void
}

const FlexiHourInfoRow: React.FC<{ locale: RootState['settings']['locale'] }> = ({ locale }) => (
  <div className={styles.infoRow}>
    <span>{locale.texts.admin.flexiHoursAdjustments.adjustments}</span>
  </div>
)

const FlexiHourHeaderRow: React.FC<{ locale: RootState['settings']['locale'] }> = ({ locale }) => (
  <div className={classNames(styles.employeeRow, styles.headerRow)}>
    <span className={styles.name}>{locale.texts.admin.flexiHoursAdjustments.columns.name}</span>
    <span>{locale.texts.admin.flexiHoursAdjustments.columns.uid}</span>
    <span>{locale.texts.admin.flexiHoursAdjustments.columns.currentFlex}</span>
    <span>{locale.texts.admin.flexiHoursAdjustments.columns.date}</span>
    <span>{locale.texts.admin.flexiHoursAdjustments.columns.adjustmentHours}</span>
    <span>{locale.texts.admin.flexiHoursAdjustments.columns.note}</span>
  </div>
)

const FlexiHourEmployeeRow: React.FC<EmployeeRowProps> = ({
  employee: { employee, adjustments, currentFlexiHours },
  newAdjustment,
  locale,
  isSelected,
  setNewAdjustment,
  toggleSelected,
  saveAdjustment,
}) => {
  const lastAdjustment = adjustments[adjustments.length - 1] ?? null
  const allButLastAdjustments = adjustments.slice(0, adjustments.length - 1)

  const isExpandable = adjustments.length > 1 || !!newAdjustment

  const nameUidAndHours = () => (
    <>
      <div onClick={toggleSelected} className={styles.name}>
        <span
          className={classNames(styles.chevron, {
            [styles.chevronInverted ?? '']: isSelected,
            [styles.showChevron ?? '']: isExpandable,
          })}
        />
        {employee.firstName} {employee.lastName}
      </div>
      <span>{employee.username}</span>
      <span>{isNaN(currentFlexiHours) ? '-' : currentFlexiHours}</span>
    </>
  )

  const adjustmentDayHoursAndNote = (adjustment: EmployeeBaseDifferenceAdjustment | null, allowAdding: boolean) => (
    <>
      <span>{adjustment?.day ? formatDayMonthYear(locale)(adjustment.day) : '-'}</span>
      <span>{adjustment?.hoursAdjustment ?? '-'}</span>
      <div className={styles.noteAndAdjustment}>
        <span>{adjustment?.note ?? '-'}</span>
        {allowAdding && (
          <button
            className={styles.textButton}
            onClick={() =>
              setNewAdjustment({
                hoursAdjustment: '0',
                note: '',
              })
            }
          >
            {locale.texts.admin.flexiHoursAdjustments.adjust}
          </button>
        )}
      </div>
    </>
  )

  return (
    <>
      {!!newAdjustment && (
        <div
          className={classNames(styles.employeeRow, styles.selectedRow)}
          data-test={`adjustment-row-${employee.username}-new`}
        >
          {nameUidAndHours()}
          <span>{formatDayMonthYear(locale)(today())}</span>
          <InputField
            value={newAdjustment.hoursAdjustment.toString()}
            onChange={(val) =>
              setNewAdjustment({
                ...newAdjustment,
                hoursAdjustment: val,
              })
            }
            className={styles.inputField}
            isError={(val) => isNaN(Number(val))}
            errorClassName={styles.inputError}
          />
          <div className={styles.noteAndAdjustment}>
            <InputField
              value={newAdjustment.note}
              onChange={(value) => setNewAdjustment({ ...newAdjustment, note: value })}
              className={styles.inputField}
            />
            <button
              className={styles.filledButton}
              onClick={saveAdjustment}
              disabled={
                newAdjustment.hoursAdjustment.trim().length === 0 ||
                isNaN(Number(newAdjustment.hoursAdjustment)) ||
                Number(newAdjustment.hoursAdjustment) === 0
              }
            >
              {locale.texts.admin.flexiHoursAdjustments.save}
            </button>
            <button
              className={styles.textButton}
              onClick={() => {
                setNewAdjustment(null)
                toggleSelected()
              }}
            >
              {locale.texts.admin.flexiHoursAdjustments.cancel}
            </button>
          </div>
        </div>
      )}
      <div
        className={classNames(styles.employeeRow, {
          [styles.selectedRow ?? '']: isSelected && isExpandable,
        })}
        data-test={`adjustment-row-${employee.username}-recent`}
      >
        {!newAdjustment && nameUidAndHours()}
        {adjustmentDayHoursAndNote(lastAdjustment, !newAdjustment)}
      </div>
      {isSelected &&
        allButLastAdjustments.map((adjustment) => (
          <div
            className={classNames(styles.employeeRow, styles.selectedRow)}
            key={adjustment.id}
            data-test={`adjustment-row-${employee.username}-previous`}
          >
            {adjustmentDayHoursAndNote(adjustment, false)}
          </div>
        ))}
    </>
  )
}

export const FlexiHoursEditorTable: React.FC<Props> = ({ employees, addAdjustment, locale }) => {
  const [selectedUser, setSelectedUser] = React.useState<string | null>(null)
  const [newAdjustment, setNewAdjustment] = React.useState<NewAdjustmentData | null>(null)

  const setUserNewAdjustment = (username: string, value: NewAdjustmentData | null) => {
    setSelectedUser(username)
    setNewAdjustment(value)
  }

  const toggleUserSelected = (username: string) => {
    if (selectedUser === username) {
      setSelectedUser(null)
      return
    }

    if (selectedUser !== username) {
      setNewAdjustment(null)
    }
    setSelectedUser(username)
  }

  const addUserAdjustment = (username: string, adjustment: NewAdjustmentData) => {
    const hoursAdjustment = Number(adjustment.hoursAdjustment)
    if (isNaN(hoursAdjustment) || hoursAdjustment === 0) {
      return
    }

    setNewAdjustment(null)
    addAdjustment(username, {
      hoursAdjustment,
      note: adjustment.note,
    })
      .then((success) => {
        if (success) {
          setUserNewAdjustment(username, null)
        }
        return success
      })
      .catch((e) => {
        console.error('Could not add flexihours adjustment', e)
        alert('Failed not add flexihours adjustment')
        setNewAdjustment(adjustment)
      })
  }

  return (
    <section className={styles.flexiHoursTable} data-test='flexihours-table'>
      <FlexiHourInfoRow locale={locale} />
      <FlexiHourHeaderRow locale={locale} />
      {employees.map((employee) => (
        <FlexiHourEmployeeRow
          locale={locale}
          key={employee.employee.username}
          saveAdjustment={() => !!newAdjustment && addUserAdjustment(employee.employee.username, newAdjustment)}
          employee={employee}
          newAdjustment={selectedUser === employee.employee.username ? newAdjustment : null}
          isSelected={selectedUser === employee.employee.username}
          setNewAdjustment={(value) => setUserNewAdjustment(employee.employee.username, value)}
          toggleSelected={() => toggleUserSelected(employee.employee.username)}
        />
      ))}
    </section>
  )
}
