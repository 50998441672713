import { format, subDays } from 'date-fns'
import _ from 'lodash'

import { formatISO, formatYearHyphenShortMonth, today, parseISODate } from './date'
import { isPartialWeek, partialWeekStart } from './util'
import {
  EmploymentContracts,
  EmploymentSeason,
  HourCodePolicy,
  HoursContractSeason,
  InvoicesAndAbsences,
  SpecialLeaveCheckpoint,
  SpecialLeaveCheckpointType,
  UsernameReportWorkEntry,
  WorkDay,
  WorkEntry,
  ReportWorkEntrySearch,
  WorkMonth,
  ReportingResponse,
  EmployeeBaseDifferenceAdjustments,
  CompactEmployeeBaseDifferenceAdjustment,
  EmployeeName,
  EmployeeBaseDifferences,
  EmployeeBaseDifferenceAdjustment,
  NewBaseDifferenceAdjustment,
} from './domain'
import { environment } from './config'

const dayDataFields = ['day', 'entries', 'total', 'difference', 'validation'] as const
const monthDataFields = ['month', 'locked', 'lockRequired'] as const

export type VersionConflictState = Record<WorkMonth['month'], WorkMonth['version']>

const getHostName = () => {
  const hostFromEnv = process.env.REACT_APP_HOURS_HOST
  const hostFromWindow =
    window && window.location.hostname.endsWith('.reaktor.com') ? window.location.hostname : 'dev.hours.reaktor.com'
  return environment() === 'development' && hostFromEnv ? hostFromEnv : hostFromWindow
}

const hostName = getHostName()

const hoursFetch = (url: string, opts: RequestInit = {}) => {
  return fetch(`https://${hostName}/api/v0/${url}`, { credentials: 'include', ...opts })
    .then(async (res) => {
      if (res.ok) {
        const text = await res.text()
        return text ? JSON.parse(text) : {}
      }
      if (res.status === 401) {
        const body = await res.json()
        console.warn('Hours session expired.')
        window.location.href = body.authenticationUrl
      }

      throw res
    })
    .catch((res) => {
      if (opts && opts.method === 'POST') {
        // 409 == conflict, it's handled in where postEntries is called
        if (res.status !== 409) {
          alert("Error posting to Hours. Changes haven't been saved. Try again.")
        }
      }

      console.error(`Hours request ${(opts && opts.method) || 'GET'} ${url} errored:`, res)
      throw res
    })
}

export const fetchLoggedInUser = () => {
  const deleteLegacyAuthentication = () => {
    document.cookie = 'hours-credentials=; expires=Fri, 01 Feb 2019 00:00:00 GMT; path=/'
  }
  deleteLegacyAuthentication()
  return hoursFetch('whoami')
}

// Contracts response also contains employee but without total difference
export const fetchEmployee = (username: string) => hoursFetch(`employees/${encodeURIComponent(username)}/${today()}`)
export const fetchCurrentlyContractedOrReportedEmployees = () => hoursFetch('employees')
export const fetchCurrentEmployeesOfCountry = (country: string): Promise<EmployeeName[]> =>
  hoursFetch(`employees/of/${encodeURIComponent(country)}`)
export const fetchPublicHolidays = (username: string) => hoursFetch(`holidays/${encodeURIComponent(username)}/public`)
export const fetchExpectedHours = (username: string) =>
  hoursFetch(`expectedHours/${encodeURIComponent(username)}`).then((expectedHours) =>
    _.chain(expectedHours).groupBy('day').mapValues(_.head).value()
  )

export const fetchCurrentHolidays = (username: string) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/currentHolidays`)

export const fetchUnpaidHolidaySummary = (username: string) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/currentHolidays/unpaid/summary`)

export const fetchFloatingHolidaySummary = (username: string) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/currentHolidays/floating2024/summary`)

export const fetchQuotaLimitedAbsenceSummary = (username: string) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/currentHolidays/quota-limited/summary`)

export const fetchUnpaidLeaveCheckpointOnDay = (username: string, date: Date) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/unpaid/on/${formatISO(date)}`)

export const fetchFloatingHolidayCheckpointOnDay = (username: string, date: Date) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/floating/on/${formatISO(date)}`)

export const fetchQuotaLimitedCheckpointOnDay = (username: string, date: Date) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/quota-limited/on/${formatISO(date)}`)

export const updateSpecialLeaveCheckpoint = (
  username: string,
  checkpoint: SpecialLeaveCheckpoint,
  checkpointType: SpecialLeaveCheckpointType
) =>
  hoursFetch(`holidays/${encodeURIComponent(username)}/currentHolidays/${checkpointType}`, {
    method: 'PUT',
    body: JSON.stringify(checkpoint),
  })

export const fetchHoursEntries = (username: string) => {
  if (!username) {
    throw new Error('fetchHoursEntries: invalid parameters')
  }

  const versionConflictState: VersionConflictState = {}
  const days: Record<WorkDay['day'], Pick<WorkDay, 'day' | 'entries' | 'total' | 'difference' | 'validation'>> = {}
  const months: Record<WorkMonth['month'], Pick<WorkMonth, 'month' | 'locked' | 'lockRequired'>> = {}

  return hoursFetch(`workMonths/${encodeURIComponent(username)}`).then((monthDatas: WorkMonth[]) => {
    for (const monthData of monthDatas) {
      if (monthData) {
        versionConflictState[monthData.month] = monthData.version
        for (const day of monthData.days) {
          days[day.day] = _.pick(day, dayDataFields)
        }
        months[monthData.month] = _.pick(monthData, monthDataFields)
      }
    }

    const lastEventId = monthDatas[0]?.lastEventId ?? 0
    return { days, versionConflictState, lastEventId, months }
  })
}

export const fetchProjectCodesWithTimeRanges = (username: string) =>
  hoursFetch(`codes/${encodeURIComponent(username)}`).then(({ absences, invoices }: InvoicesAndAbsences) => {
    const allCodes = [...absences, ...invoices]
    const flattenedCodes = _.flatten(
      allCodes.map((code) =>
        code.tasks.map((task) => ({
          name: code.name + (task.name === '' ? '' : `-${task.name}`),
          description: task.description || code.description || '',
          start: code.start,
          end: task.end || code.end,
          ..._.pick(task, ['consumesAbsenceQuota', 'policyGroup']),
        }))
      )
    )

    return flattenedCodes.filter(({ name }) => name !== '')
  })

export const fetchMonthSummaries = (username: string, start: string, end: string) =>
  hoursFetch(`reports/monthSummaries/${encodeURIComponent(username)}/${start}/${end}`)

export const calculateMostUsedHourCodes = (entries: WorkEntry[]) => {
  const hourCodeUsage = _.countBy(entries, (e) => e.hourCode)
  return _.orderBy(Object.keys(hourCodeUsage), (code) => hourCodeUsage[code] ?? 0, 'desc')
}

export const postEntries = async ({
  username,
  day,
  entries,
  versionConflictState,
}: {
  username: string
  day: string
  entries: WorkEntry[]
  versionConflictState: VersionConflictState
}) => {
  const month = formatYearHyphenShortMonth(day)
  const monthVersion = versionConflictState[month] || 0
  const defaultValues: WorkEntry = {
    note: '',
    startTime: '',
    endTime: '',
    hours: '',
    hourCode: '',
    id: undefined,
    policyMetadata: undefined,
  }
  const cleanedEntries: WorkEntry[] = entries.map((entry) => ({
    ...defaultValues,
    ..._.pick(entry, Object.keys(defaultValues)),
  }))

  const res = await hoursFetch(`workDay/${encodeURIComponent(username)}/${formatISO(day)}`, {
    method: 'POST',
    body: JSON.stringify({ entries: cleanedEntries, monthVersion, clientDate: today() }),
  })

  if (res) {
    const dayData = _.pick(res.day, dayDataFields)
    return {
      dayData,
      totalHoursDifference: res.totalHoursDifference,
      versionConflictState: { ...versionConflictState, [month]: res.monthVersion },
    }
  }
}

export const updateSetting = ({
  username,
  field,
  value,
}: {
  username: string
  field: string
  value: string | boolean | never
}) =>
  hoursFetch(`settings/${encodeURIComponent(username)}/${field}`, {
    method: 'POST',
    body: typeof value !== 'string' ? JSON.stringify(value) : value,
  })

export const logout = () =>
  hoursFetch('logout', { method: 'POST', body: '' }).then((body) => (window.location.href = body.authenticationUrl))

type EmploymentSeasonWithFinishDate = EmploymentSeason & { finish?: string }

const calculateSeasonEndDate = (
  possibleNextSeason: EmploymentSeasonWithFinishDate | undefined,
  possibleEndDate: string | undefined
) => (possibleNextSeason ? format(subDays(possibleNextSeason.start, 1), 'YYYY-MM-DD') : possibleEndDate)

const calculateSeasonEndDates = (seasons: EmploymentSeason[], possibleEndDate: string | undefined) =>
  seasons
    .reverse()
    .reduce((acc: EmploymentSeasonWithFinishDate[], season: EmploymentSeason) => {
      return [
        ...acc,
        {
          ...season,
          finish: calculateSeasonEndDate(acc.slice(-1)[0], possibleEndDate),
        },
      ]
    }, [])
    .reverse()

const nonWorkdays = (contractType: string) =>
  (isPartialWeek(contractType) ? contractType.replace(partialWeekStart, '').split('_') : []).map((s) => Number(s))

const hoursContractAsCalculatedSeasons = (hoursContracts: EmploymentContracts): HoursContractSeason[] =>
  _.flattenDeep(
    hoursContracts.contracts.map((contract) =>
      calculateSeasonEndDates(contract.seasons, contract.end).map((season) => ({
        isTrainee: season.isTrainee,
        isFixedTerm: season.isFixedTerm,
        start: season.start,
        finish: season.finish,
        type: season.hours,
        nonWorkdays: nonWorkdays(season.hours),
        expectedWorkingHoursPerDay: (day: string) =>
          nonWorkdays(season.hours).includes(parseISODate(day).getDay()) ? 0 : Number(season.fullHoursOfDay),
        company: contract.company,
      }))
    )
  )

export const fetchContracts = (username: string) =>
  hoursFetch(`contracts/${encodeURIComponent(username)}`).then(hoursContractAsCalculatedSeasons)

export const fetchManagedCountries = (username: string) =>
  hoursFetch(`employees/${encodeURIComponent(username)}/managedCountries`)

export const fetchCurrentEmployeeContractsWithVacations = (country: string) =>
  hoursFetch(`contracts/country/${encodeURIComponent(country)}/current`).then((employees) => {
    return { [country]: employees }
  })

export const fetchPoliciesGroups = (policyGroup: string): Promise<HourCodePolicy[]> =>
  hoursFetch(`policies/groups/${encodeURIComponent(policyGroup)}`)

export const fetchTasksBySearch = ({
  search,
  startMonth,
  endMonth,
}: ReportWorkEntrySearch): Promise<ReportingResponse<UsernameReportWorkEntry>> =>
  hoursFetch(`tasks/${encodeURIComponent(search)}/${encodeURIComponent(startMonth)}/${encodeURIComponent(endMonth)}`)

export const getWebsocketUrl = (previousEventId: number) =>
  `wss://${hostName}/websocket/?previousEventId=${previousEventId}`

export const updateWorkMonth = (username: string, { month, locked }: Pick<WorkMonth, 'month' | 'locked'>) =>
  hoursFetch(`workMonth/${encodeURIComponent(username)}/${month}`, {
    method: 'POST',
    body: JSON.stringify({ locked }),
  })

export const fetchUsersWithUnlockedHoursPerMonth = () =>
  hoursFetch('workMonths/unlockedHours').then((usersWithUnlockedHours) => usersWithUnlockedHours)

export const fetchAllAbsenceCodes = () =>
  hoursFetch('absenceCodes/all').then(
    (absenceCodes: { code: string; description?: string; start: string; end?: string }[]) => {
      return absenceCodes.map((codeDef) => ({
        ...codeDef,
        start: parseISODate(codeDef.start),
        end: codeDef.end ? parseISODate(codeDef.end) : undefined,
      }))
    }
  )

const toCompactBaseDifferenceAdjustments = (
  baseDifferenceAdjustments: EmployeeBaseDifferenceAdjustments
): CompactEmployeeBaseDifferenceAdjustment[] =>
  baseDifferenceAdjustments.adjustments.map(({ id, day, hoursAdjustment, note }) => ({
    id,
    hoursAdjustment,
    note,
    day: parseISODate(day),
  }))

export const fetchEmployeeBaseDifferenceAdjustments = (username: string) =>
  hoursFetch(`differences/adjustment/employee/${encodeURIComponent(username)}`).then(toCompactBaseDifferenceAdjustments)
export const fetchCountryBaseDifferenceAdjustments = (country: string): Promise<EmployeeBaseDifferenceAdjustments[]> =>
  hoursFetch(`differences/adjustment/country/${country}`)
export const postBaseDifferenceAdjustment = (
  username: string,
  adjustment: NewBaseDifferenceAdjustment
): Promise<EmployeeBaseDifferenceAdjustment> =>
  hoursFetch(`differences/adjustment/employee/${encodeURIComponent(username)}`, {
    method: 'POST',
    body: JSON.stringify(adjustment),
  })

export const fetchCountryTrailingDifferences = (country: string, count: number): Promise<EmployeeBaseDifferences> =>
  hoursFetch(`differences/trailing/${country}/${count}`)
