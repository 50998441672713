import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, useRouteMatch, Redirect, Link } from 'react-router-dom'
import styles from './css/AdminClient.module.css'
import { environment } from './lib/config'
import { RootState } from './lib/store'
import { NavBar, NavItem } from './admin/NavBar'
import { LoggedInUser } from './lib/reducers/user'
import VacationStatusView, { userHasRights as userHasRightsToVacationsView } from './admin/VacationStatusView'
import UnpaidLeaveStatusView, { userHasRights as userHasRightsToUnpaidLeaveView } from './admin/UnpaidLeaveStatusView'
import IncLeaveStatusView, { userHasRights as userHasRightsToIncLeaveStatusView } from './admin/IncLeaveStatusView'
import FlexiHoursView, { userHasRights as userHasRightsToFlexiHours } from './admin/FlexíHoursView'
import IncFloatingHolidaysView, {
  userHasRights as userHasRightsToIncFloatingHolidaysView,
} from './admin/IncFloatingHolidaysView'
import UsersWithUnlockedHoursStatusView, {
  userHasRights as userHasRightsToUnlockeddHoursStatusView,
} from './admin/UsersWithUnlockedHoursStatusView'
import ProjectAndTaskSearchView, {
  userHasRights as userHasRightsToProjectAndTaskSearch,
} from './admin/ProjectAndTaskSearchView'
import classnames from 'classnames'

interface NavItemAccess {
  navItem: NavItem
  isAccessibleToUser: (user: LoggedInUser) => boolean
}

interface AdminClientProps {
  settings: RootState['settings']
  loggedInUser: LoggedInUser
  loggedInUsername: RootState['user']['loggedInUsername']
}

const AdminClient: React.FC<AdminClientProps> = ({ settings: { locale }, loggedInUser, loggedInUsername }) => {
  const { path } = useRouteMatch()

  const navUri = (subUri: string) => `${path}/${subUri}`
  const navItems: NavItem[] = [
    {
      navItem: { title: locale.texts.admin.navBar.vacations, uri: navUri('vacations') },
      isAccessibleToUser: userHasRightsToVacationsView,
    },
    {
      navItem: { title: locale.texts.admin.navBar.unpaidLeaves, uri: navUri('unpaid-leaves') },
      isAccessibleToUser: userHasRightsToUnpaidLeaveView,
    },
    {
      navItem: { title: locale.texts.admin.navBar.reaktorIncLeaves, uri: navUri('inc-leaves') },
      isAccessibleToUser: userHasRightsToIncLeaveStatusView,
    },
    {
      navItem: { title: locale.texts.admin.navBar.reaktorIncFloatingHolidays, uri: navUri('inc-floating-holidays') },
      isAccessibleToUser: userHasRightsToIncFloatingHolidaysView,
    },
    {
      navItem: { title: locale.texts.admin.navBar.projectAndTaskSearch, uri: navUri('projectAndTaskSearch') },
      isAccessibleToUser: userHasRightsToProjectAndTaskSearch,
    },
    {
      navItem: { title: locale.texts.admin.navBar.usersWithUnlockedHours, uri: navUri('users-with-unlocked-hours') },
      isAccessibleToUser: userHasRightsToUnlockeddHoursStatusView,
    },
    {
      navItem: { title: locale.texts.admin.navBar.flexiHours, uri: navUri('flexihours') },
      isAccessibleToUser: userHasRightsToFlexiHours,
    },
  ]
    .filter((item: NavItemAccess) => item.isAccessibleToUser(loggedInUser))
    .map((item: NavItemAccess) => item.navItem)

  return (
    <main className={classnames(styles.adminClient, `app--${environment()}`)}>
      <NavBar username={loggedInUsername} navItems={navItems} />
      <div className={styles.adminUIDisclaimer}>
        <p>
          {locale.texts.hoursAdminDisclaimer}
          <a className={styles.link} target='_blank' rel='noopener noreferrer' href='/admin'>
            {locale.texts.hoursAdminDisclaimerLink}
          </a>
          .
        </p>
        <p>
          <Link className={styles.link} to='/'>
            {locale.texts.backToHoursClient}
          </Link>
        </p>
      </div>
      <Switch>
        <Redirect exact from={path} to={navUri('vacations')} />
        <Route path={navUri('vacations')}>
          <VacationStatusView />
        </Route>
        <Route path={navUri('unpaid-leaves')}>
          <UnpaidLeaveStatusView />
        </Route>
        <Route path={navUri('inc-leaves')}>
          <IncLeaveStatusView />
        </Route>
        <Route path={navUri('inc-floating-holidays')}>
          <IncFloatingHolidaysView />
        </Route>
        <Route path={navUri('projectAndTaskSearch')}>
          <ProjectAndTaskSearchView />
        </Route>
        <Route path={navUri('users-with-unlocked-hours')}>
          <UsersWithUnlockedHoursStatusView />
        </Route>
        <Route path={navUri('flexihours')}>
          <FlexiHoursView />
        </Route>
        <Redirect to={path} />
      </Switch>
    </main>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    settings: state.settings,
    loggedInUser: state.user.loggedInUser,
    loggedInUsername: state.user.loggedInUsername,
  }
}

export default connect(mapStateToProps)(AdminClient)
