import { CountryID } from '../lib/domain'
import {
  fetchCountryBaseDifferenceAdjustments,
  fetchCountryTrailingDifferences,
  fetchCurrentEmployeeContractsWithVacations,
} from '../lib/hoursApi'
import { AppDispatch } from '../lib/store'

export const updateCountryEmployees = async (selectedCountry: CountryID, dispatch: AppDispatch) => {
  try {
    const employees = await fetchCurrentEmployeeContractsWithVacations(selectedCountry)
    dispatch({ type: 'UPDATE_COUNTRY_EMPLOYEES', employeesByCountry: employees })
  } catch (e) {
    console.error('Could not load employee contracts for user', e)
  }
}

export const updateCountryBaseDifferenceAdjustmentsAndFlexihours = async (
  selectedCountry: CountryID,
  dispatch: AppDispatch
) => {
  const [employeeAdjustments, trailing] = await Promise.all([
    fetchCountryBaseDifferenceAdjustments(selectedCountry),
    fetchCountryTrailingDifferences(selectedCountry, 1),
  ])
  dispatch({
    type: 'UPDATE_COUNTRY_BASE_DIFFERENCE_ADJUSTMENTS',
    countryBaseDifferenceAdjustments: { [selectedCountry]: employeeAdjustments },
  })
  dispatch({
    type: 'UPDATE_USER_CURRENT_FLEXIHOURS',
    currentFlexihours: Object.fromEntries(
      trailing.differences
        .map((d) => {
          const flexihours = d.differenceData[0]?.[0]
          return flexihours === undefined ? null : [d.employee.username, flexihours]
        })
        .filter((x): x is [string, number] => x !== null)
    ),
  })
}
